





























import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { settings, Configuration } from "@xsenscloud/xse-styleguide";
import NotifyDialog from "@/components/common/NotifyDialog.vue";
import UserModule from "@/store/modules/user";

const userModule = namespace("user");

@Component({
  components: {
    NotifyDialog: NotifyDialog as Vue.Component
  }
})
export default class Home extends Vue {
  @userModule.Mutation("setConfiguration")
  setConfiguration!: UserModule["setConfiguration"];
  @userModule.Mutation("setNotifyFlag")
  setNotifyFlag!: UserModule["setNotifyFlag"];

  @userModule.Getter
  portals!: Configuration[];

  @userModule.Getter
  notifyFlagByRole!: boolean;

  @userModule.Getter
  notifyMessage!: string;

  @userModule.Getter
  notifyLink!: string;

  created() {
    this.setNotifyFlag(localStorage.getItem('notify') == "false" ? false : true);
    this.setConfiguration(
      (settings.APPLICATIONS as unknown) as Configuration[]
    );
  }

  hideNotify() {
    this.setNotifyFlag(false);
  }
}
