import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Account, Configuration, Permission } from "@xsenscloud/types";
import { settings } from "@xsenscloud/xse-styleguide";

export const blankAccount: Account = {
  id: -1,
  avatar: "",
  email: "test@trimm.nl",
  name: "Test",
  organization: "TRIMM",
  permissions: [],
  roles: {
    /* eslint-disable @typescript-eslint/camelcase */
    organization_admin: false,
    /* eslint-disable @typescript-eslint/camelcase */
    system_admin: false
  },
  sub: "0000-0000-00000",
  token: null
};

export function canAccessConfiguration(
  config: Configuration,
  account: Account
) {
  // When no permissons & role not required this always true
  if (config.permissions.length === 0 && Object.keys(config.roles).length === 0) {
    return true;
  }

  // check if all the required permissions are met
  const hasPermissions = config.permissions.reduce(
    (granted: boolean, permission: Permission) => {
      return account.permissions.indexOf(permission) !== -1 && granted;
    },
    true
  );

  // Check if one or more of the required roles are met
  const hasRole =
    (!config.roles.system_admin && !config.roles.organization_admin) ||
    (config.roles.system_admin && account.roles.system_admin) ||
    (config.roles.organization_admin && account.roles.organization_admin);
  return hasPermissions && hasRole;
}

@Module({ namespaced: true, preserveState: true })
class UserModule extends VuexModule {
  account: Account = blankAccount;
  skipSaveAndAnalyseDialog = false;
  messages: Array<string> = [];
  configuration: Configuration[] = [];
  showNotifyFlag: boolean | undefined; // = localStorage.getItem('notify') == "false" ? false : true;

  get sub() {
    return this.account.sub;
  }

  get owner() {
    return this.account.id;
  }

  get portals() {
    return ((settings.APPLICATIONS as unknown) as Configuration[])
      .filter(config => canAccessConfiguration(config, this.account))
      .filter(config => config.type === "portal");
  }

  get applications() {
    return ((settings.APPLICATIONS as unknown) as Configuration[]).filter(
      config => canAccessConfiguration(config, this.account)
    );
  }

  get portalHome() {
    return ((settings.APPLICATIONS as unknown) as Configuration[]).find(
      config => config.type === "portal-home"
    );
  }

  get notifyFlagByRole() {
    let notifyFlag = false;
    if (this.account.roles.organization_admin && this.showNotifyFlag && settings.ENABLE_GENERIC_NOTIFY === "ON") {
      notifyFlag = true;
    }
    return notifyFlag;
  }

  get notifyMessage() {
    let msg = "";
    if (settings.GENERIC_NOTIFY_INFO) {
      msg = settings.GENERIC_NOTIFY_INFO as string;
    }
    return msg;
  }

  get notifyLink() {
    let link = undefined;
    if (settings.NOTIFY_HREF_LINK) {
      link = settings.NOTIFY_HREF_LINK as string;
    }
    return link;
  }

  @Mutation
  clearState() {
    this.account = {
      ...blankAccount,
      token: ""
    };
  }

  @Mutation
  setAccount(account: Account) {
    this.account = {
      ...this.account,
      ...account
    };
  }

  @Mutation
  setSkipSaveAndAnalyseDialog(skip: boolean) {
    this.skipSaveAndAnalyseDialog = skip;
  }

  @Mutation
  setMessages(messages: Array<string>) {
    this.messages = messages;
  }

  @Mutation
  setNotifyFlag(flag: boolean) {
    if (flag)
      localStorage.setItem('notify', "true");
    else
      localStorage.setItem('notify', "false");
    this.showNotifyFlag = flag;
  }

  @Mutation
  setConfiguration(configuration: Configuration[]) {
    this.configuration = configuration;
  }

  @Action
  currentAccountId() {
    // implement user provisioning here for cloud reporting portal
  }

  /**
   * delete token and reset state
   */
  @Action({ commit: "clearState" })
  logout() {
    //
  }
}

export default UserModule;
