




















export default {
    name: "NotifyDialog",
    props: {
        showNotifyFlag: {
        type: Boolean,
        default: true,
        },
        showNotifyMsg: {
        type: String,
        default: "",
        },
        showNotifyLink: {
        type: String,
        default: "",
        },
    },
    data() {
        return {
            isNotifyFlag: false,
        };
    },
    methods: {
        close() {
            (this as any).$emit('close');
        },
    }
}
