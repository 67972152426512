











































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { translate } from "@/i18n";
import { Account, Configuration } from "@xsenscloud/types";

const user = namespace("user");
const auth = namespace("auth");

@Component
export default class PageHeader extends Vue {
  @Prop({ default: translate("app.title") })
  readonly title!: string;

  public langs: Array<string> = ["en-US", "nl-NL"];

  @auth.Getter
  public isAuthenticated!: boolean;

  @user.State
  public account!: Account;

  @user.Getter
  public portals!: Configuration[];

  @user.Getter
  public portalHome!: Configuration;

  get userData() {
    return this.isAuthenticated ? this.account : {};
  }

  private home() {
    window.location.href = this.portalHome.url;
  }

  private doLogout() {
    this.$store.dispatch("auth/logout");
  }

  isCurrent(label: string) {
    return label.includes("motion-cloud");
  }
}
