/* eslint-disable no-unused-vars */
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AzureB2CService } from "@xsenscloud/vue-authentication";
import store from "@/store";

@Module({ namespaced: true })
class AuthModule extends VuexModule {
  email = "";
  authenticated = false;

  get isAuthenticated() {
    return this.authenticated;
  }

  @Mutation
  clearState() {
    this.email = "";
    this.authenticated = false;
  }

  @Mutation
  setEmail(email: string) {
    this.email = email;
  }

  @Mutation
  setAuthenticated(authenticated: boolean) {
    this.authenticated = authenticated;
  }

  /**
   * delete token and reset state
   * @param commit
   */
  @Action
  logout() {
    this.context.commit("clearState");
    localStorage.removeItem('notify');
    store.dispatch("user/logout").then(() => {
      AzureB2CService.logout();
    });
  }

  @Action
  refresh() {
    this.context.commit("setAuthenticated", false);
    store.dispatch("user/logout");
  }
}

export default AuthModule;
