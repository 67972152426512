










import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/common/PageHeader.vue";

@Component({
  components: { PageHeader }
})
export default class BaseLayout extends Vue {}
