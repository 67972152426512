import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import BaseLayout from "@/views/layout/Base.vue";
import { SignInView } from "@xsenscloud/xse-styleguide";
import { redirectWhenUnauthenticated } from "@/service/guards";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: BaseLayout,
    children: [
      {
        path: "",
        name: "login",
        props: {
          nextRoute: "home"
        },
        component: SignInView
      },
      {
        path: "/home",
        name: "home",
        beforeEnter: redirectWhenUnauthenticated("login"),
        component: Home
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

export default router;
