import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import user from "@/store/modules/user";
import auth from "@/store/modules/auth";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    auth
  },
  plugins: [new VuexPersistence({ modules: ["user"] }).plugin]
});
export default store;
