import store from "@/store";
import { NavigationGuardNext, Route } from "vue-router";

/**
 * Redirect forms when not authenticated
 * @param name
 */
export const redirectWhenUnauthenticated = (name: string) => (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  if (!store.getters["auth/isAuthenticated"]) {
    next({ name });
  } else {
    next();
  }
};
