import Vue from "vue";
import VueI18n from "vue-i18n";
import { settings } from "@xsenscloud/xse-styleguide";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: { [key: string]: VueI18n.LocaleMessageObject } = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

/**
 * i18n module
 */
const i18n = new VueI18n({
  locale: (settings.I18N_LOCALE as string) || "en-US",
  fallbackLocale: (settings.I18N_FALLBACK_LOCALE as string) || "en-US",
  messages: loadLocaleMessages(),
  silentTranslationWarn: true
});

/**
 * Use for translation in templates
 * @param key
 */
const translate = (key: string) => {
  if (!key) {
    return "";
  }
  return i18n.t(key);
};

export default i18n;
export { i18n, translate };
