import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import { i18n } from "@/i18n";
import { settings } from "@xsenscloud/xse-styleguide";
import { ComponentLibrary } from "@xsenscloud/xse-styleguide";
import Authentication, { forEnv } from "@xsenscloud/vue-authentication";
import { EnvironmentConfig } from "@xsenscloud/vue-authentication/types/plugin";
import VueAppInsights from "vue-application-insights";
import VueGtm from "vue-gtm";
import * as Sentry from "@sentry/vue";

if (settings.APPINSIGHTS_INSTRUMENTATIONKEY) {
  Vue.use(VueAppInsights, {
    id: settings.APPINSIGHTS_INSTRUMENTATIONKEY,
    router
  });
}

if (settings.SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: settings.SENTRY_DSN as string,
    logErrors: true,
    environment: (settings.ENVIRONMENT as string) || "development"
  });
}

if (settings.GOOGLE_TAGMANAGER_ID) {
  Vue.use(VueGtm, {
    id: settings.GOOGLE_TAGMANAGER_ID,
    enabled: true,
    debug: settings.VUE_GTM_DEBUG === true, // Whether or not display console logs debugs (optional)
    loadScript: true,
    vueRouter: router
  });
}

Vue.config.devtools = settings.ENVIRONMENT !== "production";
Vue.config.productionTip = false;

Vue.use(ComponentLibrary);
Vue.use(Authentication, {
  configuration: forEnv((settings as unknown) as EnvironmentConfig),
  store
});

async function main() {
  const vm = new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  });
  vm.$mount("#app");
}

main();
